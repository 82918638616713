<script>
// TODO: Create new component based on ag-grid. 
// Merge "HrbrFolderSelector" and "HrbrMoveCopyAssets", if possible.

import { useHarbourStore } from '@/stores/harbour-store';
export default {
  name: 'HrbrFolderSelector',
  props: [
    'assets',
    'assetName',
    'updatetablemove',
    'mode',
    'currentfolderid',
    'currentfoldername',
    'refreshassets',
    'clearcheckedrows',
    'handleExternalFolderChange',
    'externalCallback',
    'titleOverride',
  ],
  setup() {
    return {
      harbourStore: useHarbourStore(),
    };
  },
  data() {
    return {
      folderFilterText: '',
      currentFolderStyle: {
        backgroundColor: '#2d71ad',
        color: 'white',
      },
    };
  },
  computed: {
    foldersHomeAndShared() {
      return this.harbourStore.myFolders.filter((f) => f.id == '#shared' || f.id == '#home');
    },
  },
  methods: {
    // Close button
    closeModal() {
      this.$parent.close();
    },

    // Set folder destination
    async setFolderDestination(folder) {
      // Check if there is an active text filter and the folder has subfolders, do not automatically
      // Select the folder on click. Expand and show it instead (in case user wants to select a subfolder)
      const hasSubFolders = this.harbourStore.myFolders?.some((f) => f.parent == folder.id);
      if (hasSubFolders && this.folderFilterText) {
        this.folderFilterText = '';
        const clickedFolder = document.getElementById(folder.id);
        await this.$nextTick();
        clickedFolder?.scrollIntoView();
        return;
      }

      // Allow for external folder change processing by accepting a callback instead
      if (this.handleExternalFolderChange && this.externalCallback) {
        this.externalCallback(folder);
        this.$parent.close();
        return;
      }
      // Item display text
      const self = this;
      let itemTxt = '1 item';
      if (self.assets.length > 1) {
        itemTxt = `${self.assets.length} items`;
      }

      // Validate: not the "shared" folder
      if (folder.id == '#shared') {
        this.$buefy.toast.open({
          message: "You can't add items into the Shared folder itself, but you can add items into the other \
            folders where you have edit access (including any subfolders of Shared). Please try again.",
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
        });
        return false;
      }

      // Validate: attempt to move to self? (*ok, do no action in particular, but we will roll with it)
      if (self.mode == 'MOVE' && folder.id == self.currentfolderid) {
        self.$parent.close();
        self.$buefy.toast.open({
          duration: 2500,
          message: `Success - ${itemTxt} "moved" to "${folder.name}"`,
          position: 'is-top',
          type: 'is-success',
        });
        return false;
      }

      // Target items
      const itemIdsArray = self.assets.map((d) => d.assetdisplay_displayid);

      // Validate and move
      if (self.mode == 'MOVE') {
        self.$parent.close();
        const moveCopyItemsToast = self.$buefy.toast.open({
          duration: 8000000,
          message: `&#9654;&nbsp;&nbsp;Starting move of ${itemTxt} now...`,
          position: 'is-bottom',
          type: 'is-black',
        });
        self.validateAndMoveAssetsToNewFolderDestination(
          itemIdsArray,
          folder.id,
          (successResponse) => {
            self.clearcheckedrows();
            moveCopyItemsToast.close();
            const isPermitted = successResponse.data.ispermitted;
            const isPermittedItem = successResponse.data.missingacessitem;
            if (isPermitted == false) {
              this.$buefy.toast.open({
                message: `I am afraid you do not have editor access to one or more of these items or the \
                  destination folder (*'${isPermittedItem}'). Please update and try again, or reach out to: support@harbourshare.com`,
                type: 'is-danger',
                position: 'is-top',
                duration: 5000,
              });
              return false;
            }
            self.updatetablemove(itemIdsArray);
            self.$buefy.toast.open({
              duration: 2500,
              message: `Success - ${itemTxt} moved to "${folder.name}"`,
              position: 'is-top',
              type: 'is-success',
            });
          },
        );
      }

      // Validate and copy
      if (self.mode == 'COPY') {
        self.$parent.close();
        const moveCopyItemsToast = self.$buefy.toast.open({
          duration: 8000000,
          message: `&#9654;&nbsp;&nbsp;Starting copy of ${itemTxt} now...`,
          position: 'is-bottom',
          type: 'is-black',
        });
        self.validateAndCopyAssetsToNewFolderDestination(
          itemIdsArray,
          folder.id,
          (successResponse) => {
            self.clearcheckedrows();
            moveCopyItemsToast.close();
            const isPermitted = successResponse.data.ispermitted;
            const isPermittedItem = successResponse.data.missingacessitem;
            if (isPermitted == false) {
              this.$buefy.toast.open({
                message: `I am afraid you do not have editor access to one or more of these items or the \
                  destination folder (*'${isPermittedItem}'). Please update and try again, or reach out to: support@harbourshare.com`,
                type: 'is-danger',
                position: 'is-top',
                duration: 5000,
              });
              return false;
            }
            //* Special case: copied into same origin folder --> refresh assets of the folder
            if (folder.id == self.currentfolderid) {
              self.refreshassets(self.currentfolderid, self.currentfoldername, false);
            }
            self.$buefy.toast.open({
              duration: 2500,
              message: `Success - ${itemTxt} copied to "${folder.name}"`,
              position: 'is-top',
              type: 'is-success',
            });
          },
        );
      }
    },

    // Validate access and move assets to new folder
    validateAndMoveAssetsToNewFolderDestination(itemIdsArray, targetFolderId, successCallback) {
      const self = this;

      self.$harbourData
        .post('data?filesystem-moveassetstonewdestinationfolder', {
          requesttype: 'filesystem-moveassetstonewdestinationfolder',
          assetidstomove: itemIdsArray,
          targetfolderid: targetFolderId,
          previousfolderid: self.currentfolderid,
        })
        .then((successResponse) => {
          successCallback(successResponse);
        })
        .catch((errorResponse) => {
          console.error('Error: unable to move items at this time', errorResponse);
          this.$buefy.toast.open({
            message: 'Error: unable to move items at this time: please try again later or contact support@harbourshare.com',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
          });
        });
    },

    // Validate access and copy assets to new folder
    validateAndCopyAssetsToNewFolderDestination(itemIdsArray, targetFolderId, successCallback) {
      const self = this;
      const createFromLocation = window.location.href;

      self.$harbourData
        .post('data?filesystem-copyassetstonewdestinationfolder', {
          requesttype: 'filesystem-copyassetstonewdestinationfolder',
          assetidstocopy: itemIdsArray,
          targetfolderid: targetFolderId,
          inputurl: createFromLocation,
          include_attachments: true,
        })
        .then((successResponse) => {
          successCallback(successResponse);
        })
        .catch((errorResponse) => {
          console.error('Error: unable to copy items at this time', errorResponse);
          this.$buefy.toast.open({
            message: 'Error: unable to copy items at this time: please try again later or contact support@harbourshare.com',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
          });
        });
    },

    getChildFolders(parentFolder){
      return this.harbourStore.myFolders
        .map((folder) => {
          const { ancestors: _, ...rest } = folder; // exclude ancestors
          return rest;
        })
        .filter((f) => f.parent == parentFolder.id);
    },  

    checkFolderVisible(...folders) {
      return folders.some((folder) => {
        const folderName = folder.name.toLowerCase();
        const filterText = this.folderFilterText.toLowerCase();
        return folderName.includes(filterText);
      });
    },
  },
};
</script>

<template>
  <div id="modal-card-moveassets" class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title" v-if="titleOverride">{{ titleOverride }}</p>
      <p v-else-if="mode == 'MOVE' && assets.length" class="modal-card-title">
        {{ mode === 'MOVE' ? 'Move' : 'Copy' }} "{{ assetName }}"
      </p>
    </header>
    <section class="modal-card-body" ref="folderContainer">
      <!-- filter -->
      <b-input
        style="margin-bottom: 15px"
        v-model.trim="folderFilterText"
        placeholder="Filter..."
        type="search"
        icon-pack="fal"
        icon=""></b-input>

      <!-- all folders -->
      <ul class="menu-list">
        <!-- Level0 (*Home, Shared) -->
        <li
          v-for="folder0 in foldersHomeAndShared"
          :key="folder0.id"
          >
          <span v-if="folder0.id == '#home'">
            <a
              :style="folder0.id === currentfolderid ? currentFolderStyle : null"
              :title="folder0.name"
              :id="folder0.id"
              v-show="checkFolderVisible(folder0)"
              v-on:click="setFolderDestination(folder0)">
              <b-icon pack="fal" v-if="folder0.id == '#home'" icon="folder"></b-icon>
              <b-icon pack="fal" v-if="folder0.id == '#shared'" icon="users"></b-icon>
              {{ folder0.name | truncate(35) }}
            </a>
          </span>
          <span v-if="folder0.id == '#shared'">
            <b-icon pack="fal" v-if="folder0.id == '#home'" icon="folder"></b-icon>
            <b-icon pack="fal" v-if="folder0.id == '#shared'" icon="users"></b-icon>
            {{ folder0.name | truncate(35) }}
          </span>

          <!-- Level1 -->
          <ul>
            <li
              v-for="folder1 in getChildFolders(folder0)"
              :key="folder1.id">
              <a
                :style="folder1.id === currentfolderid ? currentFolderStyle : null"
                :title="folder1.name"
                :id="folder1.id"
                v-show="checkFolderVisible(folder1, folder0)"
                v-on:click="setFolderDestination(folder1)">
                <span
                  class="movePills"
                  v-show="folderFilterText != ''"
                  v-for="ancestorFolder in folder1.ancestors"
                  :key="ancestorFolder.id">
                  {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                </span>
                <b-icon pack="fal" icon="folder"></b-icon>
                {{ folder1.name | truncate(35) }}
              </a>

              <!-- Level2 -->
              <ul>
                <li
                  v-for="folder2 in getChildFolders(folder1)"
                  :key="folder2.id">
                  <a
                    :style="folder2.id === currentfolderid ? currentFolderStyle : null"
                    :title="folder2.name"
                    :id="folder2.id"
                    v-show="checkFolderVisible(folder2, folder1, folder0)"
                    v-on:click="setFolderDestination(folder2)">
                    <span
                      class="movePills"
                      v-show="folderFilterText != ''"
                      v-for="ancestorFolder in folder2.ancestors"
                      :key="ancestorFolder.id">
                      {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                    </span>
                    <b-icon pack="fal" icon="folder"></b-icon>
                    {{ folder2.name | truncate(35) }}
                  </a>

                  <!-- Level3 -->
                  <ul>
                    <li
                      v-for="folder3 in getChildFolders(folder2)"
                      :key="folder3.id">
                      <a
                        :style="folder3.id === currentfolderid ? currentFolderStyle : null"
                        :title="folder3.name"
                        :id="folder3.id"
                        v-show="checkFolderVisible(folder3, folder2, folder1, folder0)"
                        v-on:click="setFolderDestination(folder3)">
                        <span
                          class="movePills"
                          v-show="folderFilterText != ''"
                          v-for="ancestorFolder in folder3.ancestors"
                          :key="ancestorFolder.id">
                          {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                        </span>
                        <b-icon pack="fal" icon="folder"></b-icon>
                        {{ folder3.name | truncate(35) }}
                      </a>

                      <!-- Level4 -->
                      <ul>
                        <li
                          v-for="folder4 in getChildFolders(folder3)"
                          :key="folder4.id">
                          <a
                            :style="folder4.id === currentfolderid ? currentFolderStyle : null"
                            :title="folder4.name"
                            :id="folder4.id"
                            v-show="checkFolderVisible(folder4, folder3, folder2, folder1, folder0)"
                            v-on:click="setFolderDestination(folder4)">
                            <span
                              class="movePills"
                              v-show="folderFilterText != ''"
                              v-for="ancestorFolder in folder4.ancestors"
                              :key="ancestorFolder.id">
                              {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                            </span>
                            <b-icon pack="fal" icon="folder"></b-icon>
                            {{ folder4.name | truncate(35) }}
                          </a>

                          <!-- Level5 -->
                          <ul>
                            <li
                              v-for="folder5 in getChildFolders(folder4)"
                              :key="folder5.id">
                              <a
                                :style="folder5.id === currentfolderid ? currentFolderStyle : null"
                                :title="folder5.name"
                                :id="folder5.id"
                                v-show="checkFolderVisible(folder5, folder4, folder3, folder2, folder1, folder0)"
                                v-on:click="setFolderDestination(folder5)">
                                <span
                                  class="movePills"
                                  v-show="folderFilterText != ''"
                                  v-for="ancestorFolder in folder5.ancestors"
                                  :key="ancestorFolder.id">
                                  {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                                </span>
                                <b-icon pack="fal" icon="folder"></b-icon>
                                {{ folder5.name | truncate(35) }}
                              </a>

                              <!-- Level6 -->
                              <ul>
                                <li
                                  v-for="folder6 in getChildFolders(folder5)"
                                  :key="folder6.id">
                                  <a
                                    :style="
                                      folder6.id === currentfolderid ? currentFolderStyle : null
                                    "
                                    :title="folder6.name"
                                    :id="folder6.id"
                                    v-show="checkFolderVisible(folder6, folder5, folder4, folder3, folder2, folder1, folder0)"
                                    v-on:click="setFolderDestination(folder6)">
                                    <span
                                      class="movePills"
                                      v-show="folderFilterText != ''"
                                      v-for="ancestorFolder in folder6.ancestors"
                                      :key="ancestorFolder.id">
                                      {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                                    </span>
                                    <b-icon pack="fal" icon="folder"></b-icon>
                                    {{ folder6.name | truncate(35) }}
                                  </a>

                                  <!-- Level7 -->
                                  <ul>
                                    <li
                                      v-for="folder7 in getChildFolders(folder6)"
                                      :key="folder7.id">
                                      <a
                                        :style="
                                          folder7.id === currentfolderid ? currentFolderStyle : null
                                        "
                                        :title="folder7.name"
                                        :id="folder7.id"
                                        v-show="checkFolderVisible(folder7, folder6, folder5, folder4, folder3, folder2, folder1, folder0)"
                                        v-on:click="setFolderDestination(folder7)">
                                        <span
                                          class="movePills"
                                          v-show="folderFilterText != ''"
                                          v-for="ancestorFolder in folder7.ancestors"
                                          :key="ancestorFolder.id">
                                          {{ ancestorFolder.name | truncate(15) }} >&nbsp;
                                        </span>
                                        <b-icon pack="fal" icon="folder"></b-icon>
                                        {{ folder7.name | truncate(35) }}
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    <footer class="modal-card-foot">
      <HrbrButton :button="{ text: 'Cancel', styleType: 'secondary' }" @click="closeModal()" />
    </footer>
  </div>
</template>

<style lang="postcss" scoped></style>
